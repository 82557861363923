import translationsBlueprint from 'locales/models/pl.json';

import ApiProductCategory from 'types/api/ProductCategory';
import { Locales } from 'types/locale';
import { Option } from 'types/options';
import { ColorStates } from 'types/states';

import Locale from 'models/Locale';
import Model from 'models/Model';

import { select as selectLocale } from 'utils/locale';

export const Ability = {
    enabled: true,
    disabled: false,
};

const translationsModels: typeof translationsBlueprint.comp = selectLocale({
    [Locales.En]: require('locales/models/en.json'),
    [Locales.Pl]: require('locales/models/pl.json'),
});

export default class ProductCategory implements Model {
    id: string;
    modelType: string;
    enabled: boolean;
    enabledOption: Option<boolean>;
    name: string;
    locales: {
        id: string;
        enabled: boolean;
        locale: Locale;
        name: string;
    }[];

    constructor(data: ApiProductCategory) {
        this.id = data.id;
        this.modelType = data.modelType;;
        this.enabled = Boolean(data.enabled);
        this.enabledOption = this.getEnabledOption(this.enabled);
        this.name = data.name;
        this.locales = Array.isArray(data.locales) && data.locales.map(locale => ({
            id: locale.id,
            enabled: Boolean(locale.enabled),
            locale: locale.locale && new Locale(locale.locale),
            name: locale.name,
        }));
    }

    getOption = (): Option<ProductCategory> => {
        return {
            value: this,
            label: this.locales?.[0]?.name || this.name,
        };
    };

    getSimpleOption = (): Option<string> => {
        return {
            value: this.id,
            label: this.locales?.[0]?.name || this.name,
        };
    };

    getEnabledOption = (value: boolean): Option<boolean> => {
        return enabledOptions.find(option => option.value === value);
    };
}

export const enabledOptions = [{
    value: Ability.enabled,
    label: translationsModels?.ability?.enabled,
    state: ColorStates.Success,
}, {
    value: Ability.disabled,
    label: translationsModels?.ability?.disabled,
    state: ColorStates.Alert,
}];
