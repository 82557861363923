export default {
    // Misc
    routes: require('./routes.json'),
    navigation: require('./navigation.json'),
    breadcrumbs: require('./breadcrumbs.json'),

    // Components
    componentSearchModal: require('./components/searchModal.json'),
    componentBlogsMediumBlogBox: require('./components/blogsMediumBlogBox.json'),
    componentCartDropDownMenu: require('./components/cartDropDownMenu.json'),
    componentProductSelect: require('./components/productSelect.json'),
    componentReviewerClubContactForm: require('./components/reviewerClubContactForm.json'),
    componentSearchForm: require('./components/searchForm.json'),
    componentLocationsMediumBox: require('./components/locationsMediumBox.json'),
    componentLocationsModal: require('./components/locationsModal.json'),
    componentProductBoxBuyNowModalSectionProduct: require('./components/productBoxBuyNowModal.json'),
    componentVouchersCreateForm: require('./components/vouchersCreateForm.json'),
    componentBundleCard: require('./components/bundleCard.json'),
    componentPanelProductsOrderProductsList: require('./components/panelProductsOrderProductsList.json'),
    componentPanelProductsBundleUserListElement: require('./components/panelProductsBundleUserListElement.json'),
    componentPanelProductsOrderProductsListElement: require('./components/panelProductsOrderProductsListElement.json'),
    blockLocations: require('./blocks/block-locations.json'),
    componentArticleAccessBar: require('./components/componentArticleAccessBar.json'),

    // Blocks: Change to components
    blockContact: require('./blocks/block-contact.json'),
    blockLocationsMap: require('./blocks/block-locations-map.json'),
    blockSectionPackages: require('./blocks/sectionPackages.json'),
    blockArticleCategoriesAside: require('./blocks/articleCategoriesAside.json'),
    blockSectionProducts: require('./blocks/sectionProduct.json'),
    blockVoucherBoxBuyNowModal: require('./blocks/voucherBoxBuyNowModal.json'),
    blockProductSelection: require('./blocks/blockProductSelection.json'),

    // Pages
    home: require('./pages/home.json'),
    sitemap: require('./pages/sitemap.json'),
    about: require('./pages/about.json'),
    faq: require('./pages/faq.json'),
    privacyPolicy: require('./privacyPolicy.json'),
    regulations: require('./regulations.json'),
    programRecommendationRegulations: require('./programRecommendationRegulations.json'),
    personalDataProtection: require('./personalDataProtection.json'),
    locationRegulations: require('./locationRegulations.json'),
    cart: require('./pages/cart.json'),
    paymentConfirm: require('./pages/paymentConfirm.json'),
    orderPaymentSummary: require('./pages/orderPaymentSummary.json'),
    menZone: require('./pages/menZone.json'),
    wemanZone: require('./pages/wemanZone.json'),
    article: require('./pages/article.json'),
    blog: require('./pages/blog.json'),
    blogSearch: require('./pages/blogSearch.json'),
    product: require('./pages/product.json'),
    priceList: require('./pages/priceList.json'),
    specialOffers: require('./pages/specialOffers.json'),
    vouchers: require('./pages/vouchers.json'),
    locations: require('./pages/locations.json'),
    notfound: require('./pages/notfound.json'),
    reviews: require('./pages/reviews.json'),
    partners: require('./pages/partners.json'),
    location: require('./pages/location.json'),
    tenForAnimal: require('./pages/tenForAnimal.json'),
    businessIdea: require('./pages/businessIdea.json'),
    contact: require('./pages/contact.json'),
    reviewerClub: require('./pages/reviewerClub.json'),
    technology: require('./pages/technology.json'),
    endermology: require('./pages/endermology.json'),
    recommendationProgram: require('./pages/recommendationProgram.json'),
    panelDashboard: require('./pages/panelDashboard.json'),
    panelUserSettings: require('./pages/panelUserSettings.json'),
    panelUserOrders: require('./pages/panelUserOrders.json'),
    panelUserOffers: require('./pages/panelUserOffers.json'),
    panelContact: require('./pages/panelContact.json'),
    panelInfluencer: require('./pages/panelInfluencer.json'),
    panelPrivacyProtect: require('./pages/panelPrivacyProtect.json'),
    panelWrapper: require('./pages/panelWrapper.json'),
    panelBeautyClub: require('./pages/panelBeautyClub.json'),
};
