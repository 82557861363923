import ApiProductLocale from 'types/api/ProductLocale';

import FileUpload, { getPlaceholder } from 'models/FileUpload';
import Locale from 'models/Locale';
import Model from 'models/Model';

export default class ProductLocale implements Model {
    id: string;
    locale: Locale;
    name: string;
    slug: string;
    shortDescription?: string;
    description?: string;
    image?: FileUpload;
    target: string;
    title?: string;
    metaTitle?: string;
    metaDescription?: string;

    constructor(data: ApiProductLocale) {
        this.id = data.id;
        this.locale = data.locale && new Locale(data.locale);
        this.name = data.name;
        this.slug = data.slug;
        this.title = data.title;
        this.shortDescription = data.shortDescription;
        this.description = data.description;
        this.target = data.target;
        this.image = data.image && new FileUpload(data.image) || getPlaceholder();
        this.metaTitle = data.metaTitle;
        this.metaDescription = data.metaDescription;
    }
}
